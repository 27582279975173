import React from 'react';
import Img from 'next/legacy/image';

import { styled } from '@tmap/mmm-style-guide/src/styled';
import GlobalFooter from '@tmap/mmm-style-guide/src/GlobalFooter';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import lightModeLogoUrl from '@tmap/mmm-style-guide/src/common/mmm-full-logo.png';
import darkModeLogoUrl from '@tmap/mmm-style-guide/src/common/mmm-full-logo-darkmode.png';
import { DARK_THEME } from '../lib/themes';

const FOOTER_BASE_URL = 'https://www.makemymove.com';
const TermsLink = 'https://app.termly.io/document/terms-and-conditions/293fa8f8-8d9f-42d7-b5b9-f8b407bea839';
const PivacyPolicyLink = 'https://app.termly.io/document/privacy-policy/6591afef-d955-453b-bb9c-a0a855271f8b';
const ContactUsLink = `${FOOTER_BASE_URL}/contact-us`;
const OffersLink = `${FOOTER_BASE_URL}/get-paid`;
const CommunitiesLink = `${FOOTER_BASE_URL}/moving-living`;
const ArticlesLink = `${FOOTER_BASE_URL}/articles`;
const PressLink = `${FOOTER_BASE_URL}/news`;

const footerArgs = {
  logo: (imgargs) => (<Img src={lightModeLogoUrl} title='Make My Move' alt='Make My Move' {...imgargs} />),
  logoDark: (imgargs) => (<Img src={darkModeLogoUrl} title='Make My Move' alt='Make My Move' {...imgargs} />),
  columns: [
    {
      columnName: 'General',
      links: [
        { label: 'Contact Us', url: ContactUsLink },
        { label: 'Terms and Conditions', url: TermsLink },
        { label: 'Privacy Policy', url: PivacyPolicyLink },
      ],
    },
    {
      columnName: 'Browse',
      links: [
        { label: 'Offers', url: OffersLink },
        { label: 'Communities', url: CommunitiesLink },
      ],
    },
    {
      columnName: 'Resources',
      links: [
        { label: 'Articles', url: ArticlesLink },
        { label: 'Press', url: PressLink },
      ],
    },
  ],
};

const ThemedFooter = styled(GlobalFooter)(({ theme }) => ({
  margin: '0px 0px 0px 0px',
  padding: '24px 24px 0px 24px',
  backgroundColor: (theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.veryDark),
}));

function Footer(props) {
  const { theme } = props;
  const ThemeWrapper = theme ? ThemeProvider : React.Fragment;
  const themeWrapperProps = theme ? { theme: DARK_THEME } : {};
  return (
    <ThemeWrapper {...themeWrapperProps}>
      <ThemedFooter {...props} {...footerArgs} />
    </ThemeWrapper>
  );
}

export default Footer;
