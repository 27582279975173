export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const LOAD_FROM_URL = 'LOAD_FROM_URL';
export const REVERT_FILTERS = 'REVERT_FILTERS';
export const COMPUTE_FILTERS = 'COMPUTE_FILTERS';

const FILTER_DELIM = '-';

const defaultState = {
  filters: [],
  appliedFilters: [],
  modified: false,
};

// sorts url query params into filter and nonfilter classifications
export const parseURLFilterPath = (queryParams) => {
  const filters = [];
  const nonFilters = [];
  Object.entries(queryParams).forEach((entry) => {
    const [key] = entry;
    const split = key.split(FILTER_DELIM);
    // filters look like `${int}-${string}`
    if (split.length === 2 && String(parseInt(split[0], 10)) === split[0] && split[1].length > 0) {
      return filters.push(entry);
    }
    return nonFilters.push(entry);
  });
  return {
    filterParams: Object.fromEntries(filters),
    nonFilterParams: Object.fromEntries(nonFilters),
  };
};

// encodes a computed filters list into URL format
export const encodeURLFilters = (filters) => (
  Object.fromEntries(filters.map((filter, i) => (
    [`${i}${FILTER_DELIM}${filter.key}`, JSON.stringify(filter.urlFormat)]
  )))
);

// decodes a computed filters list from URL format
export const decodeURLFilters = (filterParams, filterChoices) => {
  const filters = [];
  Object.keys(filterParams).forEach((numberedKey) => {
    const urlFormat = JSON.parse(filterParams[numberedKey]);
    const [index, key] = numberedKey.split(FILTER_DELIM); // eslint-disable-line no-unused-vars
    const template = filterChoices.find((choice) => choice.key === key);
    if (template) {
      const urlState = template.getStateFromURL(urlFormat);
      filters.push({
        key,
        state: urlState,
        ...template.getValues(urlState),
        ...template,
      });
    }
  });
  return filters;
};

// minimizes a filter list for API transport
export const apiCleanFilters = (filters) => (
  filters.map(({ key, op, values }) => ({ key, op, values }))
);

const filtersReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ADD_FILTER: {
      const { filterKey, filterChoices } = action;
      const template = filterChoices.find((filter) => filter.key === filterKey);
      const newFilter = {
        ...template,
        state: template.getInitialState(),
      };
      return {
        ...state,
        modified: true,
        filters: [...state.filters, newFilter],
      };
    }
    case REMOVE_FILTER: {
      const { filterIndex } = action;
      return {
        ...state,
        modified: true,
        filters: state.filters.filter((x, i) => i !== filterIndex),
      };
    }
    case UPDATE_FILTER: {
      const { filterIndex, controlStateKey, newState } = action;
      const filter = state.filters[filterIndex];
      const mergedNewState = { ...filter.state, ...{ [controlStateKey]: newState } };

      // can be considered unchanged if it mirrors the original url format
      const origURL = filter.urlFormat;
      const newURL = filter.getValues(mergedNewState).urlFormat;
      const filterIsModified = JSON.stringify(origURL) !== JSON.stringify(newURL);

      return {
        ...state,
        modified: filterIsModified || state.modified,
        filters: [
          ...state.filters.slice(0, filterIndex),
          { ...filter, state: mergedNewState },
          ...state.filters.slice(filterIndex + 1),
        ],
      };
    }
    case LOAD_FROM_URL: { // load all filter op/values/urlFormat from url
      const { urlEncodedFilters, filterChoices } = action;
      const filters = decodeURLFilters(urlEncodedFilters, filterChoices);
      return {
        ...state,
        modified: false,
        filters,
        appliedFilters: filters,
      };
    }
    case REVERT_FILTERS: { // reset filters to original applied state
      return {
        ...state,
        modified: false,
        filters: state.appliedFilters,
      };
    }
    case COMPUTE_FILTERS: { // use filter control state to compute op/values/urlFormat
      const computedFilters = state.filters.map((filter) => ({
        ...filter,
        ...filter.getValues(filter.state),
      }));
      // if a filter has no accurate url format (i.e. is invalid), it should not be included
      const validFilters = computedFilters.filter(({ urlFormat }) => (
        urlFormat && urlFormat.length > 0
      ));
      return {
        ...state,
        filters: validFilters,
      };
    }
    default: {
      return state;
    }
  }
};

export default filtersReducer;
