export const RECEIVE_CONTENT = 'RECEIVE_CONTENT';

const defaultState = {
  contentTypes: {}
};

const contentReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_CONTENT:
      let updatedContent = state.contentTypes;
      action.content.forEach(c => {
        updatedContent = {
          ...updatedContent,
          [c._type]: {
            ...updatedContent[c._type],
            [c._id]: c
          }
        };
      });

      return {
        ...state,
        contentTypes: updatedContent
      };
    default:
      return state;
  }
};

export default contentReducer;