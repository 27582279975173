import React, { useState } from 'react';
import SideMenu from '@tmap/mmm-style-guide/src/SideMenu';
import { useWidth } from '@tmap/mmm-style-guide/src/useWidth';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import {
  Business,
  Group,
  ExpandMore,
  FormatListBulleted,
  Article,
} from '@tmap/mmm-style-guide/src/Icon';
import { ListItemIcon } from '@tmap/mmm-style-guide/src/List';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Img from 'next/legacy/image';
import fullLogoDark from '@tmap/mmm-style-guide/src/common/mmm-full-logo-darkmode.png';
import ProfileAvatar from '../components/profileAvatar';
import ProfileMenu from '../components/profileMenu';
import Footer from '../components/footer';
import Header from '../components/header';
import useCurrentUser from '../hooks/useCurrentUser';
import { DARK_THEME } from '../lib/themes';

const insetOptions = {
  undefined: { default: '0px', sm: '0px' },
  large: { default: '100px', sm: '24px' },
  small: { default: '48px', sm: '24px' },
};

const Flex = styled('div')(() => ({
  display: 'flex',
}));
const FullWidth = styled('div')(() => ({
  width: '100%',
  minHeight: '100vh',
}));
const BodyWrapper = styled('div', {
  shouldForwardProp: (p) => !['headerOffset', 'inset'].includes(p),
})(({ theme, headerOffset, inset }) => ({
  background: 'white',
  height: '100%',
  paddingTop: headerOffset ? '100px' : '0px',
  paddingLeft: insetOptions[inset].default,
  paddingRight: insetOptions[inset].default,
  [theme.breakpoints.down('md')]: {
    paddingLeft: insetOptions[inset].sm,
    paddingRight: insetOptions[inset].sm,
  },
}));

function Logo() {
  return (
    <div style={{ marginTop: '4px' }}>
      <Link href='/'>
        <Img src={fullLogoDark} alt='Make My Move' width={2001 / 8} height={262 / 8} />
      </Link>
    </div>
  );
}

function FullWrapper(props) {
  const {
    headerOffset = false,
    inset,
    breadcrumbs,
    backUrl,
    headerColor = 'transparent',
    children,
  } = props;
  const drawerWidth = 240;
  const smallDrawerWidth = 80;
  const width = useWidth();
  const auth = useAuth0();
  const user = useCurrentUser();
  const router = useRouter();

  const [open, drawerToggle] = useState(width === null ? null : width !== 'xs');
  if (open === null && width !== null) drawerToggle(width !== 'xs');

  const appropriateDrawerWidth = open || width === 'xs' ? drawerWidth : smallDrawerWidth;

  // eslint-disable-next-line no-unused-vars
  const queryParams = '(?:[?].*)?';
  const subrouteOrQueryParams = '(?:[/?].*)?';
  const isOrgs = new RegExp(`[/]orgs${subrouteOrQueryParams}$`).test(router.asPath);
  const isMovers = new RegExp(`[/]movers${subrouteOrQueryParams}$`).test(router.asPath);
  const isTasks = new RegExp(`[/]tasks${subrouteOrQueryParams}$`).test(router.asPath);
  const isLeads = new RegExp(`[/]leads${subrouteOrQueryParams}$`).test(router.asPath);

  if (open === null) return null;
  return (
    <Flex>
      <div style={{ zIndex: 1 }}>
        <ThemeProvider theme={DARK_THEME}>
          <SideMenu
            drawerWidth={appropriateDrawerWidth}
            drawerToggle={drawerToggle}
            open={open}
            Logo={Logo}
          >
            <SideMenu.ListTop>
              <SideMenu.ResponsiveItem href='/orgs' Icon={Business} text='Organizations' isOn={isOrgs} />
              <SideMenu.ResponsiveItem href='/movers' Icon={Group} text='Movers' isOn={isMovers} />
              <SideMenu.ResponsiveItem href='/tasks' Icon={FormatListBulleted} text='Tasks' isOn={isTasks} />
              <SideMenu.ResponsiveItem href='/leads' Icon={Article} text='Leads' isOn={isLeads} />
            </SideMenu.ListTop>
            {auth.isAuthenticated && (
              <SideMenu.ListBottom>
                {width !== 'xs' && (
                  <ProfileMenu>
                    <SideMenu.Item isOn drawerWidth={appropriateDrawerWidth}>
                      <ListItemIcon sx={{ marginTop: '2px', marginBottom: '2px' }}>
                        <ProfileAvatar size={28} />
                      </ListItemIcon>
                      {open && (
                        <>
                          <SideMenu.ItemText>{user ? `${user.firstName || ''} ${user.lastName || ''}` : auth.user?.email}</SideMenu.ItemText>
                          <ExpandMore />
                        </>
                      )}
                    </SideMenu.Item>
                  </ProfileMenu>
                )}
              </SideMenu.ListBottom>
            )}
          </SideMenu>
        </ThemeProvider>
      </div>
      <Header
        drawerWidth={width === 'xs' ? 0 : appropriateDrawerWidth}
        color={headerColor}
        drawerToggle={drawerToggle}
        breadcrumbs={breadcrumbs}
        backUrl={backUrl}
      />
      <FullWidth>
        <BodyWrapper headerOffset={headerOffset} inset={inset}>
          {children}
        </BodyWrapper>
        <Footer />
      </FullWidth>
    </Flex>
  );
}

export default FullWrapper;
