import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useRequireAuth from '../hooks/useRequireAuth';

const requireUser = (Page, redirectOptions = {}) => function RequireAuth(props) {
  const { loginOptions, returnTo = null } = redirectOptions;
  const {
    requireAuthRedirect,
    isAuthenticated,
    isLoading,
    hasAuthToken,
  } = useRequireAuth();
  const router = useRouter();
  const { email, login = 'login' } = router.query;
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !hasAuthToken) {
      requireAuthRedirect(returnTo, {
        screenHint: login,
        loginHint: email,
        ...loginOptions,
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    hasAuthToken,
    loginOptions,
    returnTo,
    email,
    login,
    requireAuthRedirect,
  ]);
  return !isLoading && isAuthenticated && hasAuthToken ? <Page {...props} /> : <div />;
};

export default requireUser;
