export const CHATS_INIT = 'CHATS_INIT';
export const CHATS_LOGIN = 'CHATS_LOGIN';
export const CHATS_LOGOUT = 'CHATS_LOGOUT';
export const CHATS_CONNECT = 'CHATS_CONNECT';
export const CHATS_DISCONNECT = 'CHATS_DISCONNECT';

const defaultState = {
  client: null,
  credentials: null,
  channel: null,
}

const chatsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case CHATS_INIT: {
      const { client } = action;
      return {
        ...state,
        client,
      }
    }
    case CHATS_LOGIN: {
      const { userId, token, client } = action;
      return {
        ...state,
        client,
        credentials: { userId, token }
      }
    }
    case CHATS_LOGOUT: {
      return defaultState;
    }
    case CHATS_CONNECT: {
      const { channel } = action;
      return {
        ...state,
        channel
      }
    }
    case CHATS_DISCONNECT: {
      return {
        ...state,
        channel: null
      }
    }
    default:
      return state;
  }
}

export default chatsReducer;